<template>
  <div class="page agreement">
    <HeadBar class="headbar" title="雷石 KTV 隐私权协议"></HeadBar>
    <div class="agreement-content">
      <p class="text-indent-2">
        【雷石KTV】（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将在现有技术水平下，采取合理必要的措施来保护您的个人信息。
      </p>
      <p class="text-indent-2">
        请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》。
      </p>
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。（包括但不限于您在注册、使用服务时，所提供的姓名、身份证信息、电话、影像等其他您在注册、使用服务时涉及到的信息或相关操作。）我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：
      </p>
      <p>（一）为您提供应用内朋友互动</p>
      <p>1、业务功能一：注册成为用户</p>
      <p>
        为完成创建账号，您需提供以下信息：创建的用户名和密码、您的姓名、手机号、第三方授权登录等……；
      </p>
      <p>
        在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：手机号、工作职位、公司、教育背景等。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
      </p>
      <p>
        您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
      </p>
      <p>
        上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。
      </p>
      <p>2、业务功能二：商品展示、个性化推荐、发送促销营销信息。</p>
      <p>
        开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务，我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。所启动的定向个性化PUSH推送，我们会征求您同意推送权限授权后，再进行推送。
      </p>
      <p>当我们要将信息用于本策略未载明的其它用途时，会事先征求您的同意。</p>
      <p>
        除本《隐私权政策》之外，当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </p>
      <p>二、我们如何使用 Cookie 和同类技术</p>
      <p>（一）Cookie</p>
      <p>
        为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
        的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
        Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
      </p>
      <p>
        我们不会将 Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止
        Cookie
        的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
      </p>
      <p>（二）网站信标和像素标签</p>
      <p>
        除 Cookie
        外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
        URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时联系我们处理退订申请。
      </p>
      <p>（三）Do Not Track（请勿追踪）</p>
      <p>
        1、很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
        Track
        请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
        Do Not Track，那么我们的所有网站都会尊重您的选择。
      </p>
      <p>三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>（一）共享</p>
      <p>
        我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：
      </p>
      <p>
        1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
      </p>
      <p>
        2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
      </p>
      <p>
        3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
      </p>
      <p>
        4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
      </p>
      <p>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p>（二）转让</p>
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>
        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      </p>
      <p>
        2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>（三）公开披露</p>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>1、获得您明确同意后；</p>
      <p>
        2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
      </p>
      <p>四、我们如何保护您的个人信息</p>
      <p>
        （一）我们已使用现有技术，采取合理必要的措施来保护您的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受
        SSL 加密保护；我们同时对我们网站提供 https
        安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      </p>
      <p>
        （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      </p>
      <p>
        （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，保证您的账号安全。
      </p>
      <p>
        （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们会积极采取合理措施防止损失扩大，并协助您一起追究侵权人的法律责任。
      </p>
      <p>
        （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
      </p>
      <p>
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p>五、您的权利</p>
      <p>
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      </p>
      <p>（一）访问您的个人信息</p>
      <p>
        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
      </p>
      <p>
        账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过雷石KTV中的个人资料页面执行此类操作。
      </p>
      <p>（二）更正您的个人信息</p>
      <p>
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
      </p>
      <p>（三）删除您的个人信息</p>
      <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p>1、如果我们处理个人信息的行为违反法律法规；</p>
      <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
      <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
      <p>5、如果我们不再为您提供产品或服务。</p>
      <p>
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      </p>
      <p>
        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <p>（四）改变您授权同意的范围</p>
      <p>
        每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
      </p>
      <p>
        当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </p>
      <p>（五）约束信息系统自动决策</p>
      <p>
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
      </p>
      <p>（六）响应您的上述请求</p>
      <p>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在三十天内做出答复。
      </p>
      <p>
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </p>
      <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <p>1、与国家安全、国防安全有关的；</p>
      <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>3、与犯罪侦查、起诉和审判等有关的；</p>
      <p>4、有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
      <p>六、我们如何处理儿童的个人信息</p>
      <p>
        我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。
      </p>
      <p>
        对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
      </p>
      <p>
        尽管当地法律和习俗对儿童的定义不同，但我们将不满 14
        周岁的任何人均视为儿童。
      </p>
      <p>
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
      </p>
      <p>七、您的个人信息如何在全球范围转移</p>
      <p>
        原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
      </p>
      <p>
        由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。
      </p>
      <p>
        此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
      </p>
      <p>八、本隐私权政策如何更新</p>
      <p>
        我们可能会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式推送、要求点击同意，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
      </p>
    </div>
    <!-- <LoginModal v-if="carplayInfo.login_qr"></LoginModal> -->
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'AgreementPrivacy',
  setup() {
    onMounted(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10033,
        event_data: {
          str1: '我的',
          str2: '隐私权',
          str3: '进入隐私',
          str4: 'click',
        },
      })
    })

    return {}
  },
}
</script>
<style lang="stylus" scoped>
.page
  display flex
  flex-direction column
  height 100vh
  overflow hidden
  padding-top 0!important
  padding-bottom 0!important
  background: #000000B2
  .headbar
    background: none
    position relative
    padding 0
    margin-left -12px
    ::v-deep .header-back
      img
        margin-left 0!important
  .agreement-content
    flex 1
    overflow-y scroll
    padding 0 100px 170px
    p
      margin-bottom 40px
    @media screen and (max-width: 1200px)
      padding 0 calc(128px - 60px)  170px
      font-size: 22px;
      p
        margin-bottom 30px
.agreement
  &-title
    text-align center
    color rgba(255, 255, 255, 0.8)
    font-size 48px
    margin-top -64px
    margin-bottom 49px
    @media screen and (max-width: 1200px)
      margin-top -78px
  &-content
    line-height 40px
    font-size 30px
    color rgba(255, 255, 255, 0.4)
    padding-bottom 100px
    @media screen and (max-width: 1200px)
      padding-bottom 160px
.text-indent-2
  text-indent 62px
</style>

